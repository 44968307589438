import React from 'react'
import FaqAccordionDesktop from './FaqAccordionDesktop'
import FaqAccordionMobile from './FaqAccordionMobile'
import useFaqAccordion from './useFaqAccordion'
import { FaqAccordionProps } from './faqAccordionTypes'

const FaqAccordion = (props: FaqAccordionProps) => {
  const presentationalProps = useFaqAccordion(props)
  const { isMobile, isTablet } = presentationalProps

  if (isMobile || isTablet) {
    return <FaqAccordionMobile {...presentationalProps} />
  }
  return <FaqAccordionDesktop {...presentationalProps} />
}

export default FaqAccordion
