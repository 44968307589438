/* eslint-disable import/prefer-default-export */

import {
  Core_CreateLeadRequestInput as CreateLeadInput,
  Core_CreateBrokerRequestInput as CreateBrokerInput,
} from '@flock/flock-gql-server/src/__generated__/graphql'

const stringify = require('json-stable-stringify')

export type AddressData = {
  streetAddress: string
  city: string
  state: string
  zipcode: string
  lat: number
  lng: number
  county?: string
  streetNumber: string
  formattedAddress: string
}

export type LeadData = {
  addressData?: AddressData
  source?: string
  contactInfo?: ContactInfo
  brokerUuid?: string
  salesAssigneeUuid?: string
  answers?: string
  transactionType?: string
  eventTokenUuid?: string
}

type ContactInfo = {
  phoneNumber?: string
  fullName: string
  email: string
}

// Used with the GraphQL create lead endpoint
export const createLeadHelper = async (
  leadData: LeadData,
  parsedQueryParams: any,
  createLeadFn: (params: {
    variables: { createLeadInput: CreateLeadInput } // CreateLeadInput type
  }) => any // returns a lead
) => {
  const {
    addressData,
    source,
    contactInfo,
    answers,
    brokerUuid,
    transactionType,
    eventTokenUuid,
  } = leadData
  let parsedAnswers: any = {}

  if (typeof window !== `undefined`) {
    const { search } = window.location
    const params = new URLSearchParams(search)

    if (answers) {
      parsedAnswers = JSON.parse(answers)
    }

    const utmSource =
      params.get('utmSource') ||
      params.get('utm_source') ||
      parsedQueryParams?.utm_source ||
      ''
    const utmCampaign =
      params.get('utmCampaign') ||
      params.get('utm_campaign') ||
      parsedQueryParams?.utm_campaign ||
      ''
    const utmMedium =
      params.get('utmMedium') ||
      params.get('utm_medium') ||
      parsedQueryParams?.utm_medium ||
      ''

    // To accomodate users only inputting a first name, we need to autofil the last name to unknown if not provided
    if (contactInfo) {
      const nameData = contactInfo.fullName || ''
      const nameFirstPattern = /^[A-Za-z]+$/

      const cleanedName = nameData.match(nameFirstPattern)
        ? `${nameData} Unknown`
        : nameData

      contactInfo.fullName = cleanedName
    }

    parsedAnswers = {
      ...parsedAnswers,
      utmSource,
      utmCampaign,
      utmMedium,
    }
  }
  // deterministic stringify for webflow tests
  const updatedAnswers = stringify(parsedAnswers)
  return createLeadFn({
    variables: {
      createLeadInput: {
        fullName: contactInfo?.fullName,
        email: contactInfo?.email,
        phoneNumber: contactInfo?.phoneNumber,
        addressStreet: addressData?.streetAddress,
        addressString: addressData?.formattedAddress,
        addressCity: addressData?.city,
        addressState: addressData?.state,
        addressZip: addressData?.zipcode,
        addressLatitude: addressData?.lat,
        addressLongitude: addressData?.lng,
        addressCounty: addressData?.county,
        addressStreetNumber: addressData?.streetNumber,
        answers: updatedAnswers,
        brokerUuid,
        source,
        transactionType,
        tokenUuid: eventTokenUuid,
      },
    },
  })
}

// Used with the GraphQL create broker endpoint
export const createBrokerHelper = async (
  brokerData: ContactInfo,
  createBrokerFn: (params: {
    variables: { createBrokerInput: CreateBrokerInput } // CreateBrokerInput type
  }) => any // returns a broker
) => {
  const firstLast = brokerData.fullName.split(' ')
  return createBrokerFn({
    variables: {
      createBrokerInput: {
        firstName: firstLast[0],
        lastName: firstLast[1],
        email: brokerData.email,
        phoneNumber: brokerData.phoneNumber,
      },
    },
  })
}
