import { debounce } from '@mui/material'
import { useEffect, useState } from 'react'

export const useInView = (ids: string[]) => {
  const [currentSection, setCurrentSection] = useState(ids[0])

  const updateSections = () => {
    let current = ids[0]
    ids.forEach((id) => {
      const element = document.getElementById(id)
      const sectionTop = element?.offsetTop || Number.MAX_SAFE_INTEGER
      // eslint-disable-next-line no-restricted-globals
      if (pageYOffset >= sectionTop - 250) {
        current = id
      }
    })
    setCurrentSection(current)
  }

  const debouncedUpdateSections = debounce(updateSections, 20)

  useEffect(() => {
    window.addEventListener('scroll', () => {
      debouncedUpdateSections()
    })
  }, [debouncedUpdateSections])

  return currentSection
}

export const scrollIntoView = (id: string, yOffset = -32) => {
  const el = document.getElementById(id)
  const y = el?.getBoundingClientRect().top! + window.pageYOffset + yOffset

  window.scrollTo({
    behavior: 'smooth',
    top: y,
  })
}
