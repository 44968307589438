import React from 'react'

import { Grid, Typography } from '@mui/material'
import SectionLayout from '../SharedComponents/SectionLayout'

const FaqHeroSection = () => (
  <SectionLayout name="faq-hero-section" backgroundColor="trustBlue.main">
    <Grid container item sm={12}>
      <Typography variant="h1" color="moneyGreen.main">
        You have questions, we have answers.
      </Typography>
    </Grid>
  </SectionLayout>
)

export default FaqHeroSection
