import { scrollIntoView, TrackedAccordion, useInView } from '@flock/shared-ui'
import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import OfferPagePaper from '../../OfferPageComponents/OfferPagePaper'
import SectionLayout from '../../SharedComponents/SectionLayout'
import { FaqAccordionPresentationalProps } from './faqAccordionTypes'

const FaqAccordionDesktop = (props: FaqAccordionPresentationalProps) => {
  const { faqContent, sectionIds } = props

  const [selectedSection, setSelectedSection] = useState(0)

  const currentSection = useInView(sectionIds.map((item: any) => item))
  const onChangeSidebar = (newTab: number) => {
    scrollIntoView(sectionIds[newTab], -152)
  }

  useEffect(() => {
    setSelectedSection(
      sectionIds.map((item: any) => item).indexOf(currentSection)
    )
  }, [currentSection, sectionIds])

  return (
    <SectionLayout
      name="faq-content-section"
      backgroundColor="trustBlue.main"
      verticalPadding="0px"
    >
      <Grid container item xs={12} spacing={4}>
        <Grid item xs={4} pb="64px">
          <Box
            display="flex"
            flexDirection="column"
            position="sticky"
            top="100px"
          >
            {sectionIds.map((sectionId: any, idx: number) => (
              <Box
                sx={{
                  cursor: 'pointer',
                  padding: '16px 16px 16px 24px',
                  borderRadius: '16px',
                  background:
                    idx === selectedSection
                      ? 'var(--green2, #C9DADB)'
                      : 'unset',
                  /* drop shadow 8 */
                  boxShadow:
                    idx === selectedSection
                      ? '0px 8px 24px 0px rgba(69, 73, 77, 0.08)'
                      : 'unset',
                }}
                onClick={() => {
                  setSelectedSection(idx)
                  onChangeSidebar(idx)
                }}
              >
                <Typography
                  variant="p3"
                  sx={{
                    fontSize: '24px!important',
                    fontWeight:
                      idx === selectedSection ? '500!important' : 'unset',
                  }}
                >
                  {sectionId}
                </Typography>
              </Box>
            ))}
          </Box>
        </Grid>
        <Grid item xs={8} pb="64px">
          <Box display="flex" flexDirection="column" gap="32px">
            {faqContent.map((faqSection: { header: any; content: any }) => {
              const { header, content } = faqSection
              return (
                <OfferPagePaper borderRadius="16px" maxWidth="unset">
                  <Typography>{header}</Typography>
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap="16px"
                    pt="16px"
                    id={header}
                  >
                    {content.map(
                      (question: { title: any; text: any; idx: any }) => {
                        const { title, text } = question
                        return (
                          <TrackedAccordion
                            summary={title}
                            disableGutters
                            styleVariant="filled"
                            collapsible={false}
                            sx={{
                              '& .MuiAccordionDetails-root': {
                                padding: '0px 16px 16px 16px',
                              },
                              '& .MuiTypography-h4': {
                                fontSize: '1rem!important',
                                lineHeight: '1.5rem!important',
                                fontWeight: '500!important',
                              },
                            }}
                          >
                            <Grid item spacing={4} gap="16px">
                              <Box>
                                <Typography variant="p2">{text}</Typography>
                              </Box>
                            </Grid>
                          </TrackedAccordion>
                        )
                      }
                    )}
                  </Box>
                </OfferPagePaper>
              )
            })}
          </Box>
        </Grid>
      </Grid>
    </SectionLayout>
  )
}

export default FaqAccordionDesktop
