import { TrackedAccordion } from '@flock/shared-ui'
import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import OfferPagePaper from '../../OfferPageComponents/OfferPagePaper'
import SectionLayout from '../../SharedComponents/SectionLayout'
import { FaqAccordionPresentationalProps } from './faqAccordionTypes'

const FaqAccordionMobile = (props: FaqAccordionPresentationalProps) => {
  const { faqContent } = props
  return (
    <SectionLayout
      name="faq-content-section"
      backgroundColor="trustBlue.main"
      verticalPadding="0px"
    >
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12} pb="64px">
          <Box display="flex" flexDirection="column" gap="32px">
            {faqContent.map((faqSection: { header: any; content: any }) => {
              const { header, content } = faqSection
              return (
                <OfferPagePaper borderRadius="16px" maxWidth="unset">
                  <Typography>{header}</Typography>
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap="16px"
                    pt="16px"
                    id={header}
                  >
                    {content.map(
                      (question: { title: any; text: any; idx: any }) => {
                        const { title, text } = question
                        return (
                          <TrackedAccordion
                            summary={title}
                            disableGutters
                            styleVariant="filled"
                            collapsible={false}
                            sx={{
                              '& .MuiAccordionDetails-root': {
                                padding: '0px 16px 16px 16px',
                              },
                              '& .MuiTypography-h4': {
                                fontSize: '1rem!important',
                                lineHeight: '1.5rem!important',
                                fontWeight: '500!important',
                              },
                            }}
                          >
                            <Grid item spacing={4} gap="16px">
                              <Box>
                                <Typography variant="p2">{text}</Typography>
                              </Box>
                            </Grid>
                          </TrackedAccordion>
                        )
                      }
                    )}
                  </Box>
                </OfferPagePaper>
              )
            })}
          </Box>
        </Grid>
      </Grid>
    </SectionLayout>
  )
}

export default FaqAccordionMobile
